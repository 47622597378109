import { api } from "../_modules/api";
import { ICreatePwdDto, IResponse, ISignInPayload, IUser } from "@/_utils/types";

const url = `/api-platform/v1`

const login = async (payload: ISignInPayload): Promise<IResponse<IUser>> => {
    const { data } = await api.post<IResponse<IUser>>(`${url}/authentication/sign-in`, payload)
    return data
}
const resetPassword = async(account_email: string): Promise<IResponse<Pick<IUser, "account">>> => {
    const { data } = await api.post<IResponse<IUser>>(`${url}/authentication/forgot-password`, { account_email })
    return data
}
const verifyResetPasswordToken = async (token: string): Promise<IResponse<Pick<IUser, "account">>> => {
    const { data } = await api.post<IResponse<Pick<IUser, "account">>>(`${url}/authentication/verify-reset-password`, { token })
    return data
}
const verifyEmailConfirmationToken = async (token: string): Promise<IResponse<Pick<IUser, "account">>> => {
    const { data } = await api.post<IResponse<Pick<IUser, "account">>>(`${url}/authentication/verify-email`, { token })
    return data
}
const createPassword = async (accountId: number, payload: ICreatePwdDto): Promise<IResponse<Pick<IUser, "account">>> => {
    const { data } = await api.post<IResponse<Pick<IUser, "account">>>(`${url}/authentication/create-password/account/${accountId}`,  payload )
    return data
}

export const authService = {
    login,
    resetPassword,
    verifyResetPasswordToken,
    verifyEmailConfirmationToken,
    createPassword
}