import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import ValidateToken from "@/views/ValidateToken.vue"
import { useAuth } from "@/_modules/auth";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/reset-password",
    name: "reset-password",
    component: ValidateToken
  },
  {
    path: "/email-confirmation",
    name: "email-confirmation",
    component: ValidateToken
  },
  {
    path: "/create-password",
    name: "create-password",
    component: () => import(/* webpackChunkName: "ConfirmPassword" */ "../views/ConfirmPassword.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/update-password",
    name: "update-password",
    component: () => import(/* webpackChunkName: "ConfirmPassword" */ "../views/UpdatePassword.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "Login" */ "../views/Login.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import(/* webpackChunkName: "ForgotPassword" */ "../views/ForgotPassword.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/",
    name: "home",
    // component: Home,
    component: () => import(/* webpackChunkName: "Login" */ "../views/Home.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/member-management",
    name: "member-management",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "MemberManagement" */ "../views/members-management/MemberManagement.vue"),
    redirect: { name: 'bigData' },
    meta: { requiresAuth: true },
    children: [{
        path: '/member-management/big-data',
        name: 'bigData',
        component: () => import(/* webpackChunkName: "member-big-data" */ "../views/members-management/big-data/Overview.vue"),
        redirect: { name: 'bigData-accounts' },
        children: [{
            path: '/member-management/big-data/accounts',
            name: 'bigData-accounts',
            component: () => import(/* webpackChunkName: "bigData-accounts" */ "../views/members-management/big-data/Accounts.vue")
          },
          {
            path: '/member-management/big-data/permissions',
            name: 'bigData-permissions',
            component: () => import(/* webpackChunkName: "bigData-permissions" */ "../views/members-management/big-data/Permissions.vue"),
          }
        ]
      },
      {
        path: '/member-management/academy',
        name: 'academy',
        component: () => import(/* webpackChunkName: "member-academy" */ "../views/members-management/academy/Overview.vue"),
        redirect: { name: 'academy-accounts' },
        children: [{
            path: '/member-management/academy/accounts',
            name: 'academy-accounts',
            component: () => import(/* webpackChunkName: "academy-accounts" */ "../views/members-management/academy/Accounts.vue")
          },
          {
            path: '/member-management/academy/permissions',
            name: 'academy-permissions',
            component: () => import(/* webpackChunkName: "academy-permissions" */ "../views/members-management/academy/Permissions.vue"),
          }
        ]
      },
      {
        path: '/member-management/e-learning',
        name: 'eLearning',
        component: () => import(/* webpackChunkName: "member-eLearning" */ "../views/members-management/e-learning/Overview.vue"),
        redirect: { name: 'eLearning-accounts' },
        children: [{
            path: '/member-management/e-learning/accounts',
            name: 'eLearning-accounts',
            component: () => import(/* webpackChunkName: "e-learning-accounts" */ "../views/members-management/e-learning/Accounts.vue")
          },
          {
            path: '/member-management/e-learning/permissions',
            name: 'eLearning-permissions',
            component: () => import(/* webpackChunkName: "e-learning-permissions" */ "../views/members-management/e-learning/Permissions.vue"),
          }
        ]
      }
      /* {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: 'posts',
        component: null,
      }, */
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
//const { authenticating, token } = useAuth()
const checkAuth = () => {
  return useAuth()
}
router.beforeEach((to, from, next) => {
  const { authenticating, token } = checkAuth()
  // Not logged into a guarded route?
  if ( authenticating.value === false && to.meta.requiresAuth === true && !token.value ) {
    next({ name: 'login' })
  }
  else if ( (to.name == 'login' || to.name == 'register') && token.value ) {
    next({ name: 'home' })
  }
  // Carry On...
  else next()
})

export default router;
